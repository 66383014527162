import React, { useState, useEffect } from 'react';
import './SalesCalculator.css';

function SalesCalculator() {
  useEffect(() => {
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.overscrollBehaviorY = "";
    };
  }, []);

  const [sales, setSales] = useState();
  const [lastYearSales, setLastYearSales] = useState();
  const [goal, setGoal] = useState();
  const [sunUnits, setSunUnits] = useState();
  const [sunLove, setSunLove] = useState();
  const [customers, setCustomers] = useState();
  const [myClients, setMyClients] = useState();
  const [asCLY, setAsCLY] = useState();
  const [weather, setWeather] = useState("");
  const [notes, setNotes] = useState("");
  const [returns, setReturns] = useState();
  const [dataArray, setDataArray] = useState([]);

  const handleCopy = () => {
    const div = document.getElementById("recapDiv");
    let text = '';
    for (const node of div.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.nodeValue;
      } else if (node.tagName === 'SPAN') {
        text += node.innerText + '\n';
      } else if (node.tagName === 'BR') {
        text += '\n';
      }
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text successfully copied');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handlePopulateFields = () => {
    navigator.clipboard.readText()
      .then(text => {
        const lines = text.split('\n');
        lines.forEach(line => {
          if (line.startsWith('Sales: $')) {
            setSales(parseFloat(line.replace('Sales: $', '').trim()));
          } else if (line.startsWith('Goal: $')) {
            setGoal(parseFloat(line.replace('Goal: $', '').trim()));
          } else if (line.startsWith('% to COMP:')) {
            const match = text.match(/% to COMP: (\d+(\.\d+)?)/);
            setLastYearSales(parseFloat(line.replace('% to COMP:', '').trim()));
          } else if (line.startsWith('Sun units: ')) {
            setSunUnits(parseFloat(line.replace('Sun units: ', '').trim()));
          } else if (line.startsWith('Sun Love: ')) {
            setSunLove(parseFloat(line.replace('Sun Love: ', '').trim()));
          } else if (line.startsWith('Customers: ')) {
            setCustomers(parseFloat(line.replace('Customers: ', '').trim()));
          } else if (line.startsWith('My Clients: ')) {
            setMyClients(parseFloat(line.replace('My Clients: ', '').trim()));
          } else if (line.startsWith('A$C LY: $')) {
            setAsCLY(parseFloat(line.replace('A$C LY: $', '').trim()));
          } else if (line.startsWith('Returns: $')) {
            setReturns(parseFloat(line.replace('Returns: $', '').trim()));
          } else if (line.startsWith('Weather: ')) {
            setWeather(line.replace('Weather: ', '').trim());
          } else if (line.startsWith('Notes: ')) {
            setNotes(line.replace('Notes: ', '').trim());
          }
        });
      })
      .catch(err => {
        console.error('Failed to read clipboard: ', err);
      });
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num;
    }
    return num.toFixed(2);
  };

  const netSales = sales - returns;
  const clientPercentage = (myClients / customers) * 100;

  return (
    <div className="container" >
      <h1>#2802 Recap</h1>
      <div className="input-group">
        <label htmlFor="goal">Goal:</label>
        <input
          type="number"
          id="goal"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="sales">Sales:</label>
        <input
          type="number"
          id="sales"
          value={sales}
          onChange={(e) => setSales(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="lastYearSales">LY Sales:</label>
        <input
          type="number"
          id="lastYearSales"
          value={lastYearSales}
          onChange={(e) => setLastYearSales(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="sunUnits">Sun Units:</label>
        <input
          type="number"
          id="sunUnits"
          value={sunUnits}
          onChange={(e) => setSunUnits(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="sunLove">Sun Love:</label>
        <input
          type="number"
          id="sunLove"
          value={sunLove}
          onChange={(e) => setSunLove(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="customers">Customers:</label>
        <input
          type="number"
          id="customers"
          value={customers}
          onChange={(e) => setCustomers(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="myClients">My Clients:</label>
        <input
          type="number"
          id="myClients"
          value={myClients}
          onChange={(e) => setMyClients(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="asCLY">A$CLY:</label>
        <input
          type="number"
          id="asCLY"
          value={asCLY}
          onChange={(e) => setAsCLY(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="returns">Returns:</label>
        <input
          type="number"
          id="returns"
          value={returns}
          onChange={(e) => setReturns(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="weather">Weather:</label>
        <input
          type="text"
          id="weather"
          value={weather}
          onChange={(e) => setWeather(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label htmlFor="notes">Notes:</label>
        <input
          type="text"
          id="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>

      <br />
      <br />

      <button onClick={handleCopy} style={{ fontSize: '30px', padding: '10px 20px' }}>
        Copy Recap
      </button>
      <br />
      <br />
      <br />

      <div id="recapDiv">
        <span>Closing Recap</span>
        <br />
        <span>Sales: ${formatNumber(netSales)}</span>
        <span>Goal: ${goal} </span>
        <span>% to Goal: {formatNumber(netSales / goal * 100)}% </span>
        <span>% to COMP: {formatNumber(netSales / lastYearSales * 100)}% </span>
        <br />
        <span>Sun units: {sunUnits}</span>
        <br />
        <span>Sun Love: {sunLove}</span>
        <span>% to goal: {formatNumber(sunLove / sunUnits * 100)}%</span>
        <br />
        <span>Customers: {customers}</span>
        <span>Customer Goal: {formatNumber(goal / asCLY)}</span>
        <br />
        <span>My Clients: {myClients}</span>
        <span>% to Customers: {formatNumber(clientPercentage)}%</span>
        <br />
        <span>A$C: {formatNumber(netSales / customers)}</span>
        <span>A$C LY: ${asCLY}</span>
        <span>% to A$C: {formatNumber((netSales / customers) / asCLY * 100)}%</span>
        <br />
        <span>Returns: ${returns}</span>
        <span>Weather: {weather}</span><br />
        <span>Notes: {notes}</span><br />
        <span>Thank you.</span>
      </div>

      <br />
      <br />
      <br />
      <br />

      <button onClick={handlePopulateFields}>Populate Fields</button>
    </div>
  );
}

export default SalesCalculator;
